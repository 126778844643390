<template>
  <div class="Sales">
    <div style="
          padding-bottom: 100px;
          height: 100%;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
        ">
      <div class="top" style="font-weight:bold;color:#333">
        <!-- <div class="flex">
          <el-select
            v-model="selectKey"
            style="width: 130px"
            @change="selectChange"
          >
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
          </el-select>
          <el-input :placeholder="placeholder" v-model="queryParam.Keywords" @input="inputChange"  @keydown.enter.native="getCollectionStatistics"></el-input>
        </div> -->
        <!-- <div class="flex" style="margin-top: 10px">
          <el-date-picker v-model="StartTime" type="date" placeholder="开始日期" @change="startTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-date-picker v-model="EndTime" type="date" placeholder="结束日期" @change="endTimeChange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </div> -->
        客户: {{ customer }}
      </div>
      <div>
        <!-- 明细页面明细页面明细页面明细页面 -->

      </div>
      <el-table ref="queryForm" :height="tableHeight" element-loading-text="拼命加载中" :data="tableData"
        v-loading="loading" v-el-table-infinite-scroll="scrollLoad" :infinite-scroll-immediate="false"
        :infinite-scroll-disabled="scrollDisaled">
        <el-table-column prop="CreateTime" label="日期" width="100"> </el-table-column>
        <el-table-column prop="OverdueDay" label="逾期天数">
          <template slot-scope="scope">
            {{ scope.row.OverdueDay + ' 天' }}
          </template>
        </el-table-column>
        <el-table-column prop="Yumoeney" label="逾期金额"> </el-table-column>
        <el-table-column label="操作" width="70" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDetailCopy(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="width:100%;position:fixed;bottom:0;padding:20px;background-color:#fff;display:flex;">
      <div style="flex:5">总计:</div>
      <div style="flex:5">{{ OverdueDayAmount == 0 ? OverdueDayAmount : toThousands(OverdueDayAmount) }}</div>
    </div>
  </div>
</template>

<script>
import { toThousands } from "../../../utils/Tools";
export default {
  data() {
    return {
      scrollDisaled: false,
      tableHeight: 200,
      // 客户名字
      customer: "",
      // 千分位格式化
      toThousands,
      // 下拉选择数据
      options: [{
        value: '0',
        label: '客户'
      }, {
        value: '1',
        label: '业务员'
      }],
      // 表格属性数据
      tablePropListData: [
        {
          label: "客户",
          prop: 'Name'
        },
        {
          label: "逾期金额",
          prop: "OverdueAmount"
        }
      ],
      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户名称进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: { CompanyId: "", Keyword: "", Type: 0 },
      // 逾期总金额合计
      OverdueDayAmount: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
      document.documentElement.clientHeight - this.$refs.queryForm.$el.offsetHeight;
      this.tableHeight = height+70;
    });
    // // 获取收款统计列表数据
    // this.getCollectionStatistics()
  },
  created() {
    document.title = "逾期统计"

    console.log('获取过来的query', this.$route.query)
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId
    this.pagination.token = this.$route.query.token

    if (this.$route.query.obj) {
      this.queryParam.CompanyId = this.$route.query.obj.CompanyId ? this.$route.query.obj.CompanyId : JSON.parse(localStorage.getItem('detailObj')).CompanyId
      this.queryParam.Keyword = this.$route.query.obj.Id ? this.$route.query.obj.Id : JSON.parse(localStorage.getItem('detailObj')).Id
      this.queryParam.Type = this.$route.query.obj.Type ? this.$route.query.obj.Type : JSON.parse(localStorage.getItem('detailObj')).Type
    }


    this.toGetList();


  },
  methods: {

    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取逾期天数统计详情列表数据
      this.getCollectionStatistics();
      // // 获取逾期天数底部总金额统计数据
      this.GetOverdueDetailsDi()
    },
    // 搜索
    search() {
      // 同时获取逾期天数统计列表数据和逾期天数统计总金额数据
      this.toGetList()
    },

    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (this.pagination.current > 1 && this.tableData.length == this.pagination.total) {
        return
      }
      // 获取逾期天数统计详情列表数据
      this.getCollectionStatistics();
    },

    // 逾期表格操作明细
    handleDetailCopy(row) {
      let obj = {
        Id: row.Id,
        billlistId: row.billlistId
      }
      console.log('点击的逾期表格明细的详情结果', row, obj)

      this.$router.push({ path: "/App/Receipt", query: { Id: row.Id} })

    },



    // 时间选择
    startTimeChange(e) {
      console.log('开始时间', e)
      this.queryParam.StartDate = e
      this.toGetList()

    },
    endTimeChange(e) {
      console.log('结束时间', e)
      this.queryParam.EndDate = e
      this.toGetList()
    },
    // 搜索输入框输入
    inputChange(e) {
      console.log('搜索框输入', e)
      this.queryParam.Keywords = e
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.getCollectionStatistics();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getCollectionStatistics();
    },

      // 获取逾期总金额统计数据
    GetOverdueDetailsDi(Id, CommpayId, Type){
      this.$http
        .post("/BO/BIlling/GetOverdueDetailsDi", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search:this.queryParam
        })
        .then((resJson) => {
          if (resJson.Success) {
            console.log('获取逾期总金额统计统计统计沟通结果',resJson.Data )
            this.loading = false;
            this.OverdueDayAmount = resJson.Data.Yumoeney
            
          } else {
            this.loading = false;
          }
        });
    }, 
    // 获取逾期天数详情统计列表数据
    getCollectionStatistics() {
      this.loading = true;
      this.$http.post("/BO/BIlling/GetOverdueDetails", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
        
          this.loading = false;

          // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
          if (resJson.Data.length == 0) {
            this.scrollDisaled = true;
            return
          }

          this.scrollDisaled = false;

          if (resJson.Data) {
            const pagination = { ...this.pagination };
            pagination.total = resJson.Total;
            this.pagination = pagination;
          }
          resJson.Data.forEach(item => {
            // 赋值客户名字渲染
            this.customer = item.cCusName
            item.Yumoeney = toThousands(item.Yumoeney)
            item.CreateTime = (item.CreateTime).substring(0, 10)
          })

          this.tableData = this.tableData.concat(resJson.Data);// 分页请求的数据，追加到任务列表中
            if (this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
              return
            }
        } else {
          this.loading = false
        }
      });
    },
    // 下拉选择事件
    selectChange(e) {
      this.queryParam.Keywords = ""
      console.log(e);
      if (e == 0) {
        this.placeholder = "请输入客户名称进行查询"
      } else {
        this.placeholder = "请输入业务员名称进行查询"

      }
      this.queryParam.Type = e
      this.toGetList()
      // this.placeholder = "请输入" + e + "名称进行查询";
      // this.searchKey = "";
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
}
</style>